
// Other
import AppViewer from '@/core/AppViewer';
import IModel from '@/core/IModel';
import { Raw } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

export class Props {
    model = prop<IModel>({ type: Object, required: true });
    viewer = prop<Raw<AppViewer>>({ type: Object, required: true });
}
@Options({
    components: {},
})
export default class VAppViewerScene extends Vue.with(Props) {
    declare $refs: {
        canvas: HTMLCanvasElement;
    };

    async mounted(): Promise<void> {
        await this.viewer.mount(this.$refs.canvas).loadAsync(this.model.downloadUri);
        this.$emit('viewer-mounted', this.viewer);
    }
}
