
// Components
import VAppViewerScene from './VAppViewerScene.vue';
import VAppViewerToolkit from './VAppViewerToolkit.vue';
import VAppViewerSideMenu from './VAppViewerSideMenu.vue';

// Other
import IOptions from '@/values/IOptions';
import { Options, Vue, prop } from 'vue-class-component';
import AppViewerBuilder from '@/core/AppViewerBuilder';
import { Raw, markRaw } from 'vue';
import AppViewer from '@/core/AppViewer';
import IModel from '@/core/IModel';

export class Props {
    model = prop<IModel>({ type: Object, required: true });
    options = prop<IOptions>({ type: Object, required: true });
}

@Options({
    components: {
        VAppViewerScene,
        VAppViewerToolkit,
        VAppViewerSideMenu,
    },
})
export default class VAppViewer extends Vue.with(Props) {
    viewer: Raw<AppViewer> | null = null;
    readyToView = false;
    fullscreen = false;

    viewerMounted() {
        this.readyToView = true;
    }

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    }

    created(): void {
        const builder = new AppViewerBuilder();

        builder.configureDefault();

        builder.addGrid();
        builder.addLoaderOverlay();
        builder.addNavigationCube();
        builder.addInteractiveClipping();

        this.viewer = markRaw(builder.build());
    }
}
