import { CameraType, NavigationMode } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';
import { AppViewerCommandWithParams } from '../AppViewerCommandWithParams';

export interface ConfigureCameraParams {
    fov?: number;
    cameraType?: CameraType;
    navigationMode?: NavigationMode;
}

export default class ConfigureCameraCommand
    extends AppViewerCommandWithParams<ConfigureCameraParams>
    implements IAppViewerCommand
{
    name = 'ConfigureCamera';

    handle(context: IAppViewerCommandContext<ConfigureCameraParams>): void | Promise<void> {
        if (!context.command.params) {
            return;
        }

        const xBimViewer = context.viewer.core;

        const fov = context.command.params?.fov;
        if (typeof fov !== 'undefined' && fov !== xBimViewer.cameraProperties.fov) {
            xBimViewer.cameraProperties.fov = fov;
        }

        const cameraType = context.command.params?.cameraType;
        if (typeof cameraType !== 'undefined' && cameraType !== xBimViewer.camera) {
            xBimViewer.camera = cameraType;
        }

        const navigationMode = context.command.params?.navigationMode;
        if (typeof navigationMode !== 'undefined' && navigationMode !== xBimViewer.navigationMode) {
            xBimViewer.navigationMode = navigationMode;
        }
    }
}
