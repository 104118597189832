import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class ViewFitSelectedElementCommand implements IAppViewerCommand {
    name = 'ViewFitSelectedElement';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        if (context.selected.length === 0) {
            return;
        }

        const lastSelectedElement = context.selected[context.selected.length - 1];

        context.viewer.showAsync(lastSelectedElement);
    }
}
