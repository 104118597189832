import ClipCommand from './Handlers/ClipCommand';
import ConfigureCameraCommand from './Handlers/ConfigureCameraCommand';
import ConfigureMouseControlCommand from './Handlers/ConfigureMouseControlCommand';
import HideIfcSpacesCommand from './Handlers/HideIfcSpacesCommand';
import HideSelectedElementsCommand from './Handlers/HideSelectedElementsCommand';
import ResetStateOfAllElementsCommand from './Handlers/ResetStateAllElementsCommand';
import ShowIfcSpacesCommand from './Handlers/ShowIfcSpacesCommand';
import ShowOnlySelectedCommand from './Handlers/ShowOnlySelectedCommand';
import UnClipCommand from './Handlers/UnClipCommand';
import ViewFitSelectedElementCommand from './Handlers/ViewFitSelectedElementCommand';
import { IAppViewerCommand } from './IAppViewerCommand';

export class AppViewerCommands {
    static readonly Clip = new ClipCommand();
    static readonly UnClip = new UnClipCommand();
    static readonly ConfigureCamera = new ConfigureCameraCommand();
    static readonly ConfigureMouseControl = new ConfigureMouseControlCommand();
    static readonly HideSelectedElements = new HideSelectedElementsCommand();
    static readonly ViewFitSelectedElement = new ViewFitSelectedElementCommand();
    static readonly HideIfcSpaces = new HideIfcSpacesCommand();
    static readonly ResetStateOfAllElements = new ResetStateOfAllElementsCommand();
    static readonly ShowIfcSpaces = new ShowIfcSpacesCommand();
    static readonly ShowOnlySelected = new ShowOnlySelectedCommand();
}

const commands = Object.values(AppViewerCommands) as IAppViewerCommand[];

export default commands;
