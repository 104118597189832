export default interface IModel {
    modelId: string;
    error: boolean;
    errorMessage: string;
    status: ModelStatus;
    readyToView: boolean;
    downloadUri: string;
    createdAt: string;
}

export enum ModelStatus {
    Pending = 'Pending',
    Converting = 'Converting',
    ReadyToView = 'ReadyToView',
    PendingDeletion = 'PendingDeletion',
    Error = 'Error',
}
