import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["fullscreen", "with-toolkit", "with-side-menu"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VAppViewerToolkit = _resolveComponent("VAppViewerToolkit")!
  const _component_VAppViewerScene = _resolveComponent("VAppViewerScene")!
  const _component_VAppViewerSideMenu = _resolveComponent("VAppViewerSideMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "viewer",
    fullscreen: _ctx.fullscreen,
    "with-toolkit": _ctx.options.withToolkit,
    "with-side-menu": _ctx.options.withSideMenu
  }, [
    (_ctx.options.withToolkit && _ctx.readyToView)
      ? (_openBlock(), _createBlock(_component_VAppViewerToolkit, {
          key: 0,
          class: "viewer__part viewer__part--toolkit",
          name: 'test.ifc',
          viewer: _ctx.viewer,
          onToggleFullscreen: _ctx.toggleFullscreen
        }, null, 8, ["name", "viewer", "onToggleFullscreen"]))
      : _createCommentVNode("", true),
    _createVNode(_component_VAppViewerScene, {
      class: "viewer__part viewer__part--scene",
      model: _ctx.model,
      viewer: _ctx.viewer,
      onViewerMounted: _ctx.viewerMounted
    }, null, 8, ["model", "viewer", "onViewerMounted"]),
    (_ctx.options.withSideMenu && _ctx.readyToView)
      ? (_openBlock(), _createBlock(_component_VAppViewerSideMenu, {
          key: 1,
          class: "viewer__part viewer__part--side-menu"
        }))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}