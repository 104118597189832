import { ProductType, State } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class HideIfcSpacesCommand implements IAppViewerCommand {
    name = 'HideIfcSpaces';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        context.viewer.core.activeHandles;
        context.viewer.setState(ProductType.IFCSPACE, State.HIDDEN);
    }
}
