import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "viewer-side-menu" }
const _hoisted_2 = { class: "viewer-side-menu__row viewer-side-menu__row--tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VTabs = _resolveComponent("VTabs")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VTabs, {
        tabs: _ctx.tabs,
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
      }, null, 8, ["tabs", "modelValue"])
    ]),
    (_ctx.tab)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tab.component), { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}