
import { Options, Vue, prop } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

export interface ITab {
    value: string;
    label: string;
}

export class Props {
    tabs = prop<ITab[]>({ type: Array, required: true });
    modelValue = prop<ITab>({ type: Object });
}

@Options({
    components: {},
})
export default class VTabs extends Vue.with(Props) {
    declare $refs: {
        slider: HTMLDivElement;
    };

    public moveSlider() {
        const activeTab = this.$el.querySelector('.v-tabs__tab--active') as HTMLDivElement;

        if (!activeTab) {
            return;
        }

        const slider = this.$refs.slider;

        slider.style.left = `${activeTab.offsetLeft}px`;
        slider.style.width = `${activeTab.clientWidth}px`;
    }

    public select(tab: ITab) {
        this.$emit('update:modelValue', tab);
    }

    @Watch('options', { deep: true })
    @Watch('modelValue')
    public sliderPositionChangeHandler() {
        this.$nextTick(() => this.moveSlider());
    }

    mounted() {
        if (this.modelValue === null && this.tabs.length > 0) {
            this.select(this.tabs[0]);
        }

        setTimeout(() => this.moveSlider(), 100);
    }
}
