import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-tabs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "v-tabs__tab-label" }
const _hoisted_4 = {
  ref: "slider",
  class: "v-tabs__slider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
      return (_openBlock(), _createElementBlock("button", {
        type: "button",
        class: _normalizeClass(["v-tabs__tab", { 'v-tabs__tab--active': tab === _ctx.modelValue }]),
        key: index,
        onClick: ($event: any) => (_ctx.select(tab))
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(tab.label), 1)
      ], 10, _hoisted_2))
    }), 128)),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}