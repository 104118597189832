import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class ResetStateAllElementsCommand implements IAppViewerCommand {
    name = 'ResetStateOfAllElements';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        for (const activeHandle of context.viewer.core.activeHandles) {
            activeHandle.resetState();
        }
        context.viewer.selected = [];
    }
}
