import { State } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class HideSelectedElementsCommand implements IAppViewerCommand {
    name = 'HideSelectedElements';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        context.viewer.setState(context.selected, State.HIDDEN);
    }
}
