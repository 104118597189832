import { createI18n } from 'vue-i18n';
import ru from '../resources/langs/ru.json';

type Schema = typeof ru;

const i18n = createI18n<[Schema], 'ru'>({
    locale: 'ru', // set locale
    fallbackLocale: 'ru', // set fallback locale
    messages: {
        ru,
    },
});

export default i18n;
