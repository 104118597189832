
// Components
import VAppViewer from './components/VAppViewer.vue';

// Other
import IOptions from '@/values/IOptions';
import { kickTheHorse, errorMessage, errorIllustration, ErrorIllustrations } from './utils/ErrorBadger';
import { Options, Vue, setup } from 'vue-class-component';
import { getModel } from './utils/Api';
import IModel, { ModelStatus } from './core/IModel';

const defaultOptions: IOptions = {
    withToolkit: true,
    withSideMenu: false,
};

const MyBoolean = (value: string) => {
    return value === 'true' || value === '1';
};

@Options({
    components: { VAppViewer },
})
export default class App extends Vue {
    loading = true;
    model!: IModel;
    modelId = '';
    accessToken = '';
    errorMessage = setup(() => errorMessage);
    errorIllustration = setup(() => errorIllustration);
    options: IOptions = {
        withToolkit: true,
        withSideMenu: false,
    };

    getOptionOrDefault<T>(
        options: { [key: string]: string },
        key: string,
        defaultValue: T,
        type: (value: string) => T,
    ): T {
        if (key in options) {
            return type(options[key]);
        }

        return defaultValue;
    }

    async created() {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        if (!params.mid) {
            kickTheHorse(this.$t('errors.incorrectUri'), ErrorIllustrations.BadRequest);
            return;
        }

        this.modelId = params.mid;

        try {
            const response = await getModel(this.modelId);

            this.model = response.data;

            switch (this.model.status) {
                case ModelStatus.Error:
                    kickTheHorse(this.$t('errors.convertationError'), ErrorIllustrations.SomethingWentWrong);
                    break;
                case ModelStatus.Pending:
                    kickTheHorse(this.$t('errors.modelIsConverting'), ErrorIllustrations.SomethingWentWrong);
                    break;
                case ModelStatus.PendingDeletion:
                    kickTheHorse(this.$t('errors.modelWillBeDelete'), ErrorIllustrations.SomethingWentWrong);
                    break;
                case ModelStatus.Converting:
                    kickTheHorse(this.$t('errors.modelIsConverting'), ErrorIllustrations.InProgress);
                    break;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error?.response?.status === 404) {
                kickTheHorse(this.$t('errors.modelNotFound'), ErrorIllustrations.NotFound);
                return;
            }

            kickTheHorse(this.$t('errors.failedToGetModelInfo'), ErrorIllustrations.SomethingWentWrong);
        } finally {
            this.loading = false;
        }

        this.options.withToolkit = this.getOptionOrDefault(params, 'toolkit', defaultOptions.withToolkit, MyBoolean);
        this.options.withSideMenu = this.getOptionOrDefault(
            params,
            'side-menu',
            defaultOptions.withSideMenu,
            MyBoolean,
        );
    }
}
