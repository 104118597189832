import AppViewer, { ConfigureViewer } from './AppViewer';
import { AppViewerPluginName } from './AppViewerPluginName';
import { Grid, NavigationCube, LoaderOverlay, InteractiveClippingPlane } from '@xbim/viewer';

export default class AppViewerBuilder {
    private _configure: ConfigureViewer[] = [];

    public build(): AppViewer {
        return new AppViewer(this._configure);
    }

    public configureDefault() {
        this._configure.push((viewer) => {
            const xBimViewer = viewer.core;

            xBimViewer.hoverPickEnabled = true;
            xBimViewer.cameraProperties.fov = 53;
            xBimViewer.background = [242, 242, 255, 255];
        });
    }

    public addGrid(): void {
        this._configure.push((viewer) => {
            const grid = new Grid();

            grid.zFactor = 20;
            grid.colour = [0, 0, 0, 255];

            viewer.addPlugin(AppViewerPluginName.Grid, grid);
        });
    }

    public addLoaderOverlay(): void {
        this._configure.push((viewer) => {
            const loader = new LoaderOverlay();

            viewer.addPlugin(AppViewerPluginName.LoaderOverlay, loader);
        });
    }

    public addInteractiveClipping(): void {
        this._configure.push((viewer) => {
            const interactiveClipping = new InteractiveClippingPlane();

            viewer.addPlugin(AppViewerPluginName.InteractiveClipping, interactiveClipping);
        });
    }

    public addNavigationCube(): void {
        this._configure.push((viewer) => {
            const cube = new NavigationCube();

            cube.ratio = 0.05;
            cube.activeAlpha = 0.85;
            cube.passiveAlpha = 0.85;

            viewer.addPlugin(AppViewerPluginName.NavigationCube, cube);
        });
    }
}
