import { Viewer as XBimViewer, IPlugin, LoaderOverlay, ViewType, State, ProductType } from '@xbim/viewer';
import { IAppViewerCommand } from './IAppViewerCommand';
import { IAppViewerCommandContext } from './IAppViewerCommandContext';
import { AppViewerPluginName } from './AppViewerPluginName';
import { IModelElement } from './IModelElement';
import commands from './AppViewerCommands';
import AppViewerUtils from './AppViewerUtils';
import { kickTheHorse } from '@/utils/ErrorBadger';
import VueI18n from '@/plugins/VueI18n';

export type ConfigureViewer = (viewer: AppViewer) => void;
export type AppViewerPlugins = { [name: string]: IPlugin };

class AppViewer {
    private _xBimViewer?: XBimViewer;
    private readonly _plugins: AppViewerPlugins = {};
    private readonly _commands: IAppViewerCommand[] = commands;
    private readonly _configure: ConfigureViewer[] = [];

    public mounted = false;
    public selected: IModelElement[] = [];

    public get core(): XBimViewer {
        if (!this._xBimViewer) {
            throw new Error('Viewer is not mounted');
        }

        return this._xBimViewer;
    }

    public get loader(): LoaderOverlay | undefined {
        return this.getPlugin<LoaderOverlay>(AppViewerPluginName.LoaderOverlay);
    }

    public constructor(configure: ConfigureViewer[]) {
        this._configure = configure;
    }

    public async showAsync(
        element: IModelElement,
        viewType: ViewType = ViewType.DEFAULT,
        withAnimation = true,
    ): Promise<void> {
        try {
            if (!this._xBimViewer) {
                throw new Error('Viewer is not mounted');
            }

            await this._xBimViewer.show(viewType, element.id, element.model, withAnimation);
        } catch (error) {
            kickTheHorse(VueI18n.global.t('errors.somethingWentWrong'));
        }
    }

    public setState(elements: IModelElement | IModelElement[] | ProductType, state: State): void {
        if (!this._xBimViewer) {
            throw new Error('Viewer is not mounted');
        }

        if (typeof elements === 'number') {
            this._xBimViewer!.setState(state, elements);
            return;
        }

        if (!Array.isArray(elements)) {
            elements = [elements];
        }

        AppViewerUtils.forAllElements(elements, (model, elementIds) => {
            this._xBimViewer!.setState(state, elementIds, model);
        });
    }

    public mount(canvas: HTMLCanvasElement): AppViewer {
        this._xBimViewer = new XBimViewer(canvas);

        let configure = this._configure.pop();
        while (configure) {
            configure(this);
            configure = this._configure.pop();
        }

        this._mount();

        this.mounted = true;
        return this;
    }

    public exec(command: IAppViewerCommand): void {
        if (!this._xBimViewer) {
            throw new Error('Viewer is not mounted');
        }

        const context = this._buildCommandContext(command);

        try {
            for (const handler of this._commands) {
                if (handler.name === command.name) {
                    handler.handle(context);
                }
            }
        } catch (error) {
            kickTheHorse(VueI18n.global.t('errors.somethingWentWrong'));
        }
    }

    public async loadAsync(uri: string): Promise<void> {
        if (!this._xBimViewer) {
            throw new Error('Viewer is not mounted');
        }

        if (this.loader) {
            this.loader.show();
        }

        try {
            const response = await fetch(uri);

            await this._xBimViewer.loadAsync(await response.blob());
        } catch (error) {
            kickTheHorse(VueI18n.global.t('errors.loadWexBimError'));
        }
    }

    public addPlugin(name: AppViewerPluginName, plugin: IPlugin): void {
        if (!this._xBimViewer) {
            throw new Error('Viewer is not mounted');
        }

        this._plugins[name] = plugin;
        this._xBimViewer.addPlugin(plugin);
    }

    public getPlugin<TPlugin extends IPlugin = IPlugin>(name: AppViewerPluginName): TPlugin | undefined {
        return this._plugins[name] as TPlugin;
    }

    private _mount(): void {
        try {
            this._bind();
            this._start();
        } catch (error) {
            kickTheHorse(VueI18n.global.t('errors.somethingWentWrong'));
        }
    }

    private _bind() {
        this._xBimViewer!.on('loaded', this._loaded.bind(this));
    }

    private _start() {
        this._xBimViewer!.start();
    }

    private async _loaded() {
        try {
            if (this.loader) {
                this.loader.hide();
            }

            await this._xBimViewer!.show(ViewType.FRONT, undefined, undefined, false);
        } catch (error) {
            kickTheHorse(VueI18n.global.t('errors.somethingWentWrong'));
        }
    }

    private _buildCommandContext(command: IAppViewerCommand): IAppViewerCommandContext {
        return {
            command,
            viewer: this,
            selected: this.selected,
        };
    }
}

export default AppViewer;
