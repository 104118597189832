import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-layer"
}
const _hoisted_2 = { class: "error-layer__message" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VAppViewer = _resolveComponent("VAppViewer")!

  return (_ctx.errorMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1),
        _createElementVNode("img", {
          class: "error-layer__image",
          src: _ctx.errorIllustration,
          alt: "error"
        }, null, 8, _hoisted_3)
      ]))
    : (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_VAppViewer, {
          key: 1,
          options: _ctx.options,
          model: _ctx.model
        }, null, 8, ["options", "model"]))
      : _createCommentVNode("", true)
}