import { InteractiveClippingPlane } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';
import { AppViewerPluginName } from '../AppViewerPluginName';

export default class ClipCommand implements IAppViewerCommand {
    name = 'Clip';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        const plugin = context.viewer.getPlugin<InteractiveClippingPlane>(AppViewerPluginName.InteractiveClipping);

        if (!plugin) {
            return;
        }

        plugin.stopped = false;
    }
}
