import { State } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class ShowOnlySelectedCommand implements IAppViewerCommand {
    name = 'ShowOnlySelected';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        for (const activeHandle of context.viewer.core.activeHandles) {
            const elements = Object.keys(activeHandle['_model'].productMaps).map((id) => parseInt(id, 10));

            activeHandle.setState(State.HIDDEN, elements);
        }

        context.viewer.setState(context.viewer.selected, State.UNDEFINED);
    }
}
