
// Other
import AppViewer from '@/core/AppViewer';
import { AppViewerCommands } from '@/core/AppViewerCommands';
import { Options, Vue, prop } from 'vue-class-component';
import { CameraType, State } from '@xbim/viewer';

export class Props {
    name = prop<string>({ type: String, required: true });
    viewer = prop<AppViewer>({ type: Object, required: true });
}

export enum ToolType {
    View = 1,
    Hide,
    Highlight,
}

@Options({
    components: {},
})
export default class VAppViewerScene extends Vue.with(Props) {
    toolType = ToolType.Highlight;
    cameraType = CameraType.PERSPECTIVE;
    clip = false;
    showIfcSpaces = false;
    showOnlySelected = false;
    firstPersonNavigationMode = false;

    get isHideMode() {
        return this.toolType === ToolType.Hide;
    }

    get isSelectMode() {
        return this.toolType === ToolType.Highlight;
    }

    public blur(): void {
        if (document.activeElement) {
            (document.activeElement as HTMLDivElement).blur();
        }
    }

    public viewFit(): void {
        this.viewer.exec(AppViewerCommands.ViewFitSelectedElement);

        this.blur();
    }

    public toggleClip() {
        this.clip = !this.clip;

        this.viewer.exec(this.clip ? AppViewerCommands.Clip : AppViewerCommands.UnClip);

        this.blur();
    }

    public toggleHideMode() {
        this.toolType = this.toolType === ToolType.Hide ? ToolType.Highlight : ToolType.Hide;

        this.viewer.exec(
            AppViewerCommands.ConfigureMouseControl.withParams({
                state: this.toolType === ToolType.Hide ? State.HIDDEN : State.HIGHLIGHTED,
                altKey: true,
                ctrlKey: true,
                clickEmpty: true,
                multiple: false,
                forceChangeState: true,
            }),
        );

        this.blur();
    }

    public toggleHighlightMode() {
        if (this.toolType !== ToolType.Highlight) {
            this.toolType = ToolType.Highlight;

            this.viewer.exec(
                AppViewerCommands.ConfigureMouseControl.withParams({
                    state: State.HIGHLIGHTED,
                    altKey: true,
                    ctrlKey: true,
                    clickEmpty: true,
                    multiple: false,
                    forceChangeState: true,
                }),
            );
        }

        this.blur();
    }

    public toggleCameraType(): void {
        this.cameraType = this.cameraType === CameraType.PERSPECTIVE ? CameraType.ORTHOGONAL : CameraType.PERSPECTIVE;

        this.viewer.exec(
            AppViewerCommands.ConfigureCamera.withParams({
                cameraType: this.cameraType,
            }),
        );

        this.blur();
    }

    public toggleShowIfcSpaces() {
        this.showIfcSpaces = !this.showIfcSpaces;

        this.viewer.exec(this.showIfcSpaces ? AppViewerCommands.ShowIfcSpaces : AppViewerCommands.HideIfcSpaces);
    }

    public toggleShowOnlySelected() {
        this.showOnlySelected = !this.showOnlySelected;

        this.viewer.exec(
            this.showOnlySelected ? AppViewerCommands.ShowOnlySelected : AppViewerCommands.ResetStateOfAllElements,
        );
    }

    public toggleFirstPersonNavigationMode(): void {
        this.firstPersonNavigationMode = !this.firstPersonNavigationMode;

        this.viewer.exec(
            AppViewerCommands.ConfigureCamera.withParams({
                navigationMode: this.firstPersonNavigationMode ? 'walk' : 'orbit',
            }),
        );
    }

    public mounted() {
        this.viewer.exec(
            AppViewerCommands.ConfigureMouseControl.withParams({
                state: State.HIGHLIGHTED,
                altKey: true,
                ctrlKey: true,
                clickEmpty: true,
                multiple: false,
                forceChangeState: true,
            }),
        );
    }
}
