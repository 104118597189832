import { ref } from 'vue';
import error400Svg from '../assets/error-400.svg';
import error500Svg from '../assets/error-500.svg';
import inProgressSvg from '../assets/in-progress.svg';

export class ErrorIllustrations {
    static readonly NotFound: string = error400Svg;
    static readonly BadRequest: string = error400Svg;
    static readonly SomethingWentWrong: string = error500Svg;
    static readonly InProgress: string = inProgressSvg;
}

export const errorMessage = ref('');
export const errorIllustration = ref(ErrorIllustrations.SomethingWentWrong);

/**
 * Shows the application error message.
 * @param error Error message
 */
export function kickTheHorse(error: string, illustration: string = ErrorIllustrations.SomethingWentWrong) {
    errorMessage.value = error;
    errorIllustration.value = illustration;
}
