import { ProductType, State } from '@xbim/viewer';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';

export default class ShowIfcSpacesCommand implements IAppViewerCommand {
    name = 'ShowIfcSpaces';

    handle(context: IAppViewerCommandContext): void | Promise<void> {
        context.viewer.setState(ProductType.IFCSPACE, State.UNDEFINED);
    }
}
