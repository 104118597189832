import { State, ViewerInteractionEvent } from '@xbim/viewer';
import { AppViewerCommandWithParams } from '../AppViewerCommandWithParams';
import { IAppViewerCommand } from '../IAppViewerCommand';
import { IAppViewerCommandContext } from '../IAppViewerCommandContext';
import AppViewer from '../AppViewer';
import AppViewerUtils from '../AppViewerUtils';

export interface SetMouseModeParams {
    /**
     * New state on click.
     */
    state: State;
    /**
     * View fit on clicked element.
     */
    altKey: boolean;
    /**
     * Multiple selection mode on click.
     */
    ctrlKey: boolean;
    /**
     * Reset selected elements on click empty.
     */
    clickEmpty: boolean;
    /**
     * Multiple selection mode without ctrlKey on click.
     */
    multiple: boolean;
    /**
     * Apply new state on selected elements.
     */
    forceChangeState: boolean;
}

export default class ConfigureMouseControlCommand
    extends AppViewerCommandWithParams<SetMouseModeParams>
    implements IAppViewerCommand
{
    name = 'ConfigureMouseControl';
    callbackWithContext: ((event: ViewerInteractionEvent) => void) | null = null;

    handle(context: IAppViewerCommandContext<SetMouseModeParams>): void | Promise<void> {
        const viewer = context.viewer;
        const params = context.command.params;

        context.viewer.core.off('pick', this.callbackWithContext);
        this.callbackWithContext = (event) => this.clickHandler(event, viewer, params);
        context.viewer.core.on('pick', this.callbackWithContext);

        if (params?.forceChangeState) {
            viewer.setState(viewer.selected, params?.state);
        }
    }

    clickHandler(event: ViewerInteractionEvent, viewer: AppViewer, params: SetMouseModeParams | null): void {
        const clickEmpty = !event.id || !event.model;
        if (params?.clickEmpty && clickEmpty) {
            AppViewerUtils.resetStateOfSelectedElements(viewer);
            viewer.selected = [];
            return;
        }

        if (params?.altKey && event.event.altKey) {
            viewer.showAsync({
                id: event.id,
                model: event.model,
            });
            return;
        }

        const index = viewer.selected.findIndex((element) => element.id === event.id && element.model === event.model);

        const selected = index !== -1;
        if (!selected) {
            AppViewerUtils.resetStateOfSelectedElements(viewer);

            if (params?.multiple || (params?.ctrlKey && event.event.ctrlKey)) {
                viewer.selected.push({
                    id: event.id,
                    model: event.model,
                });
            } else {
                viewer.selected = [
                    {
                        id: event.id,
                        model: event.model,
                    },
                ];
            }
        } else {
            AppViewerUtils.resetStateOfSelectedElements(viewer);

            viewer.selected.splice(index, 1);
        }

        if (params) {
            viewer.setState(viewer.selected, params?.state);
        }
    }
}
