import { State } from '@xbim/viewer';
import AppViewer from './AppViewer';
import { IModelElement } from './IModelElement';
import groupBy from 'lodash.groupby';

export default class AppViewerUtils {
    public static forAllElements(elements: IModelElement[], callback: (model: number, elementIds: number[]) => void) {
        const models = groupBy(elements, 'model');

        for (const key in models) {
            if (Object.prototype.hasOwnProperty.call(models, key)) {
                const model = parseInt(key, 10);
                const elementIds = models[key].map((item) => item.id);

                callback(model, elementIds);
            }
        }
    }

    public static forAllSelectedElements(viewer: AppViewer, callback: (model: number, elementIds: number[]) => void) {
        AppViewerUtils.forAllElements(viewer.selected, callback);
    }

    public static resetStateOfSelectedElements(viewer: AppViewer) {
        viewer.setState(viewer.selected, State.UNDEFINED);
    }
}
