import { IAppViewerCommandWithParams } from './IAppViewerCommand';
import { IAppViewerCommandContext } from './IAppViewerCommandContext';

export abstract class AppViewerCommandWithParams<TParams = null> implements IAppViewerCommandWithParams<TParams> {
    abstract name: string;
    params!: TParams | null;

    constructor(params: TParams | null = null) {
        this.params = params;
    }

    abstract handle(context: IAppViewerCommandContext<TParams>): void | Promise<void>;

    public withParams(params: TParams): IAppViewerCommandWithParams<TParams> {
        const { constructor } = Object.getPrototypeOf(this);
        return new constructor(params);
    }
}
