import IModel from '@/core/IModel';
import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.VUE_APP_API_URL as string;
const axiosInstance = axios.create({
    baseURL,
});

export function getModel(modelId: string): Promise<AxiosResponse<IModel>> {
    return axiosInstance.get(`/api/models/${modelId}`);
}
