
// Components
import VTabs, { ITab } from './VTabs.vue';
import VAppViewerSideMenuExplorer from './VAppViewerSideMenuExplorer.vue';
import VAppViewerSideMenuProperties from './VAppViewerSideMenuProperties.vue';

// Other
import { markRaw } from 'vue';
import VueI18n from '@/plugins/VueI18n';
import { Options, Vue } from 'vue-class-component';

interface ISideMenuTab extends ITab {
    component: unknown;
}

@Options({
    components: {
        VTabs,
        VAppViewerSideMenuExplorer,
        VAppViewerSideMenuProperties,
    },
})
export default class VAppViewerSideMenu extends Vue {
    tab: ISideMenuTab | null = null;
    tabs: ISideMenuTab[] = [
        {
            value: 'explorer',
            label: VueI18n.global.t('explorer'),
            component: markRaw(VAppViewerSideMenuExplorer),
        },
        {
            value: 'properties',
            label: VueI18n.global.t('properties'),
            component: markRaw(VAppViewerSideMenuProperties),
        },
    ];
}
